.newsletter-section {
  display: flex;
  align-items: center;
  height: 633px;

  @media screen and (max-width: 960px) {
    margin-top: 125px;
    height: 368px;
  }

  @media screen and (max-width: 480px) {
    margin: 125px 10px 10px 10px;
  }


  .container {
    display: flex;
  }

  .newsletter__content {
    background: #fae885;
    width: 1300px;
    height: 393px;
    border-radius: 30px;
    text-align: left;

    @media screen and (max-width: 960px) {
      width: 100%;
      height: 315px;
    }

    &__container {
      padding: 80px 130px;

      @media screen and (max-width: 960px) {
        padding: 50px 100px;
        text-align: center;
      }

      @media screen and (max-width: 768px) {
        padding: 50px 0px;
      }

      &-title {
        margin-bottom: 30px;
        h3 {
          text-transform: uppercase;
          width: 460px;
          font-size: 40px;
          font-family: "LilitaOne", sans-serif;
          color: #000000;

          @media screen and (max-width: 960px) {
            font-size: 32px;
          }

          @media screen and (max-width: 480px) {
            font-size: 28px;
            width: 100%;
          }
        }
      }
      &-undertitle {
        @media screen and (max-width: 480px) {
          display: flex;
          justify-content: center;
        }
 
        p {
          width: 530px;
          font-size: 16px;
          color: #000000;

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          @media screen and (max-width: 480px) {
            width: 90%;
          }
        }
      }
      &-button {
        margin-top: 30px;
       
       @media screen and (max-width: 480px) {
        display: flex;
        justify-content: center;
       }
      }
    }
  }
}
