@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.who-we-are-section {
  display: flex;
  align-items: center;
  height: 513px;
  background: #ccc6f1;
  position: relative;


  @media screen and (max-width: 960px) {
    height: 465px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-content {
    text-align: center;
    width: 640px;
    display: flex;
    flex-direction: column;
    // gap: 40px;
    align-items: center;
    position: relative;

    @media screen and (max-width: 960px) {
      width: 505px;
    }

    @media screen and (max-width: 480px) {
      width: 305px;
    }

    &_title {
      text-transform: uppercase;
      margin-bottom: 24px;
    }

    h2,
    h3 {
      font-size: 40px;
      font-family: "LilitaOne", sans-serif;

      @media screen and (max-width: 960px) {
        font-size: 32px;
        letter-spacing: -0.02em;
      }
    }

    p {
      font-size: 16px;
      margin-bottom: 40px;
    }

    // button {
    //   width: 200px;
    //   height: 41px;
    //   padding: 10px 35px;
    //   border-radius: 10px;
    //   background-color: #000;
    //   color: #fff;
    //   font-size: 14px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    // }

    .stars {
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;

      .star {
        position: absolute;
        animation: float 4s ease-in-out infinite;
      }

      .star1 {
        top: -15%;
        left: -15%;

        @media screen and (max-width: 678px) {
          top: -18%;
          left: -1%;
        }
      }

      .star2 {
        top: 25%;
        left: -25%;
        animation-delay: 0.5s;

        @media screen and (max-width: 678px) {
          top: 25%;
          left: -2%;
        }
      }

      .star3 {
        top: 43%;
        right: -20%;
        animation-delay: 1s;

        @media screen and (max-width: 678px) {
          top: 75%;
          right: 2%;
        }

        @media screen and (max-width: 480px) {
          top: 86%;
          right: -6%;
          width: 11%;
        }

     
      }

      .star4 {
        top: 30%;
        right: -30%;
        animation-delay: 1.5s;

        @media screen and (max-width: 678px) {
          top: 105%;
          right: -2%;
        }

        @media screen and (max-width: 480px) {
          top: 98%;
          right: 3%;
        }
      }

   

      .star5 {
        bottom: 12%;
        right: -28%;
        animation-delay: 2s;

        @media screen and (max-width: 678px) {
          bottom: -18%;
          right: 16%;
        }

        @media screen and (max-width: 480px) {
          top: 98%;
          right: -14%;
        }
      }
    }
  }
}
