.our-goals {
  display: flex;
  align-items: center;
  height: 960px;

  @media screen and (max-width: 1280px) {
    height: 760px;
  }

  
  @media screen and (max-width: 980px) {
    height: 1222px;
  }

  @media screen and (max-width: 480px) {
    height: 1050px;
  }


  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;

    @media screen and (max-width: 980px) {
      flex-direction: column-reverse;
      gap: 8px;
    }

    &-img {
      width: 580px;
      height: 660px;

      @media screen and (max-width: 1280px) {
        width: 430px;
        height: 490px;
      }

        
  @media screen and (max-width: 980px) {
    width: 497px;
    height: 566px;
  }

  @media screen and (max-width: 1280px) {
    width: 441px;
    height: 438px;
  }

  @media screen and (max-width: 480px) {
    width: 330px;
    height: 390px;
  }

      img {
        width: 100%;
      }
    }

    &-text {

      &-title {
        margin-bottom: 20px;
        text-align: left;
        @media screen and (max-width: 980px) {
          text-align: center;
        }
        h3 {
          font-size: 40px;
          font-family: "LilitaOne", sans-serif;
          @media screen and (max-width: 980px) {
            text-align: center;
            font-size: 32px;
          }
        }
      }

      &-list {
        &-item {
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 18px;

          @media screen and (max-width: 980px) {
            justify-content: center;
          }

       

          svg{
            path{
              // display: none;
            }
          }
          p {
            font-size: 24px;
            color: #070707;

            
              @media screen and (max-width: 1280px) {
                font-size: 16px;
              }
          }
        }
      }

      &-button {
        margin-top: 40px;

        .custom-yellow-hover-btn{
          @media screen and (max-width: 980px) {
            margin: 40px auto 0 auto;
          }
        }

       
        // button {
        //   padding: 10px 35px;
        //   border-radius: 10px;
        //   background-color: #000;
        //   color: #fff;
        //   font-size: 14px;
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        // }
      }
     
    }
  }
}
