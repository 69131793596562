.home-section {
  display: flex;
  align-items: center;
  height: 752px;

  @media screen and (max-width: 960px) {
    height: 807px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 960px) {
      flex-direction: column;
      text-align: center;
    }
  }

  &_left {
    width: 530px;
    text-align: left;

    // @media screen and (max-width: 960px) {
    //   text-align: center;
    // }

    @media screen and (max-width: 960px) {
      text-align: center;
      width: 430px;
      margin-bottom: 60px;
    }

    @media screen and (max-width: 480px) {
      width: 365px;
    }

    h2 {
      font-size: 72px;
      font-family: "LilitaOne", sans-serif;
      font-weight: 400;
      line-height: 70px;
      text-transform: uppercase;
      margin-bottom: 30px;
      // letter-spacing: -0.02em;

      @media screen and (max-width: 960px) {
        font-size: 42px;
        letter-spacing: -0.02em;
        margin-bottom: 18px;
      }
    }

    p {
      font-size: 24px;
      margin-bottom: 40px;

      @media screen and (max-width: 960px) {
        font-size: 20px;
      }
    }


    button{
      @media screen and (max-width: 960px) {
       margin: 0 auto;
      }
    }



  }

  @media screen and (max-width: 1440px) {
    &_right {
      width: 580px;
      height: 478px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 1280px) {
    &_right {
      width: 430px;
      height: 352px;

      @media screen and (max-width: 480px) {
        width: 330px;
        height: 252px;
      }
    }
  }
}
