.policy-terms-container {
    padding: 80px 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: "Outfit", sans-serif;
    font-weight: 400;
    
    h1, h2 {
      color: #333;
    }
  
    h1 {
      font-size: 2em;
      margin-bottom: 20px;
      text-align: center;
    }
  
    h2 {
      font-size: 1.5em;
      margin-top: 20px;
      margin-bottom: 10px;
      color: #333;
    }
  
    p {
      font-size: 1em;
      line-height: 1.6;
      margin-bottom: 10px;
    }
  
    strong {
      font-weight: bold;
    }
  
    @media (max-width: 768px) {
      padding: 15px;
      h1 {
        font-size: 1.5em;
      }
      h2 {
        font-size: 1.2em;
      }
      p {
        font-size: 0.9em;
      }
    }
  
    @media (max-width: 480px) {
      padding: 10px;
      h1 {
        font-size: 1.2em;
      }
      h2 {
        font-size: 1em;
      }
      p {
        font-size: 0.8em;
      }
    }
  }
  