@import "./fonts/fonts.scss";

// App.scss
.App {
  position: relative;
}
.container {
  margin: 0 auto;

  @media (min-width: 1440px) {
    max-width: 1300px;
  }

  @media (max-width: 1439px) and (min-width: 1280px) {
    max-width: 1180px;
  }

  @media (max-width: 1280px) and (min-width: 960px) {
    max-width: 920px;
  }

  @media (max-width: 960px) and (min-width: 768px) {
    max-width: 840px;
  }

  @media (max-width: 768px) and (min-width: 480px) {
    max-width: 440px;
  }
}
