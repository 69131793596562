.footer-section {
  height: 517px;
  background: #070707;
  position: relative;
  color: #fff;
  display: flex;
  align-items: center;

  @media screen and (max-width: 980px) {
    height: 100%;
    padding: 90px 0;
  }

  @media screen and (max-width: 980px) {
    flex-direction: column;
  }

  .container {
    width: 1300px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 480px) {
      flex-direction: column;
      width: 100%;
    }
  }

  &-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    
  @media screen and (max-width: 980px) {
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-bottom: 60px;
  }

    &_nav {
      display: flex;
      text-align: end;

      @media screen and (max-width: 980px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }

    .block {
      width: 200px;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 40px;

      li {
        list-style: none;
        font-size: 16px;
        cursor: pointer;

        a{
          color: #fff;
          font-weight: 400;

          &:hover{
            font-weight: 700;
          }
        }
      }
    }
  }

  &-bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @media screen and (max-width: 980px) {
      flex-direction: column;
      align-items: center;
      gap: 45px;
    }
  

    &_left {
      text-align: left;
      width: 530px;

      

      @media screen and (max-width: 980px) {
        text-align: center;
      }
    
      @media screen and (max-width: 678px) {
        width: 440px;
      }

      @media screen and (max-width: 480px) {
        width: 280px;
      }
      
      .social {
        display: flex;
        gap: 15px;
        text-transform: uppercase;
        font-size: 16px;
        margin-bottom: 25px;

        @media screen and (max-width: 980px) {
          justify-content: center;
          margin-bottom: 80px;
        }

        a{
          color: #fff;
          font-weight: 400;

          &:hover{
            font-weight: 700;
          }
        }
      }

      .undertext {
        font-size: 12px;

       
      }
    }

    &_rigth {
      .copyright {
        p {
          font-size: 12px;
        }
      }
    }
  }
}

.footer-frog-img {
  position: absolute;
  top: -245px;
  right: 200px;
  animation: slide 5s infinite alternate;

  @media screen and (max-width: 980px) {
    top: -95px;
    width: 127px;
    height: 116px;
  }

  img{
    width: 100%;
    height: 100%;
  }
}

@keyframes slide {
  from {
    right: 200px;
  }
  to {
    right: 0;
  }
}
