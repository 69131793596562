.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background: #f9f9f999;
  backdrop-filter: blur(10px);
  font-size: 16px;
  font-weight: 400;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 768px) {
      max-width: 85%;
    }
  }

  .left-conatiner {
    display: flex;
    gap: 200px;

    @media screen and (max-width: 1280px) {
      gap: 100px;
    }

    svg{
      @media screen and (max-width: 480px) {
        width: 150px;
      }
    }
   

    ul {
      @media screen and (max-width: 960px) {
        display: none;
      }
    }
  }

  img {
    width: 240px;
    height: 43px;
  }

  ul {
    display: flex;
    gap: 12px;
    list-style: none;
    padding: 0;

    li {
      width: 74px;
      cursor: pointer;
      text-align: center;
      box-sizing: border-box;
      &:hover {
        font-weight: 700;
      }
    }
  }

  .button {
    height: 41px;
  }
  button {
    padding: 5px 35px;
    border-radius: 10px;
    background-color: #000;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
