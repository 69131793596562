.how-it-work {
  display: flex;
  align-items: flex-start;
  margin-top: 120px;
  height: 1225px;

  
  @media screen and (max-width: 980px) {
    height: 1571px;
    margin-top: 85px;
  }

  &_title {
    font-family: "LilitaOne", sans-serif;
    text-align: center;
    margin-bottom: 130px;

    @media screen and (max-width: 960px) {
      margin-bottom: 90px;
    }

    h2 {
      text-transform: uppercase;
      font-size: 40px;

      @media screen and (max-width: 980px) {
        font-size: 32px;
      }
    }
  }

  &_arrows-1440 {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 200px;
    position: relative;
    height: 720px;
    width: 1300px;

    @media screen and (max-width: 960px) {
      display: none;
    }

    h5{
      font-size: 24px;
      font-weight: 400;
      font-family: 'LilitaOne', sans-serif;
      margin: 5px 0 0 0;
      text-transform: uppercase;
    }

    .arrow1 {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      .step1 {
        width: 310px;
        position: absolute;
        top: 0;
        left: -225px;
      }

      .step2 {
        width: 310px;
        position: absolute;
        top: 155px;
        right: -265px;
      }
    }

    .arrow2 {
      position: absolute;
      top: 300px;
      right: 360px;

      @media screen and (max-width: 1280px) {
        right: 195px;
      }

      @media screen and (max-width: 960px) {
        right: -5px;
      }

      .step3 {
        width: 310px;
        position: absolute;
        top: 20px;
        left: -225px;
      }
    }

    .arrow3 {
      position: absolute;
      right: 455px;
      top: 520px;

      @media screen and (max-width: 1280px) {
        right: 290px;
      }

      @media screen and (max-width: 960px) {
        right: 90px;
      }

      .step4 {
        width: 310px;
        position: absolute;
        top: 20px;
        right: -245px;
      }
    }

    @media screen and (max-width: 1320px) {
      width: 1100px;
    }

    @media screen and (max-width: 1280px) {
      width: 960px;
    }

    @media screen and (max-width: 960px) {
      width: 560px;
    }
  }

  &_arrows-960 {
    display: none;
    align-items: center;
    flex-direction: column;
    height: 1130px;
    width: 310px;

    @media screen and (max-width: 960px) {
      display: flex;
      gap: 13px;
    }

    h5{
      font-size: 24px;
      font-weight: 400;
      font-family: 'LilitaOne', sans-serif;
      margin: 5px 0 0 0;
      text-transform: uppercase;
    }

    .item1, .item2, .item3, .item4{
      width: 310px;
    }

    .arrow{

    }

  }

  .join-btn {
    margin-top: 165px;
    display: flex;
    justify-content: center;

    // button {
    //   width: 200px;
    //   height: 41px;
    //   padding: 10px 35px;
    //   border-radius: 10px;
    //   background-color: #000;
    //   color: #fff;
    //   font-size: 14px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    // }
  }
}
