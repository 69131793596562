.questions-section {
  display: flex;
  align-items: center;
  height: 730px;

   @media screen and (max-width: 960px) {
    height: 689px;
  }

  .questions__container {
    &-content {
      height: 500px;
      display: flex;
      align-items: flex-start;
      gap: 220px;
      align-items: baseline;

      @media screen and (max-width: 1280px) {
        gap: 15px;
        }

      @media screen and (max-width: 960px) {
        gap: 8px;
        flex-direction: column;
        align-items: center;
      }

      &-title {
        width: 421px;

        @media screen and (max-width: 1280px) {
          width: 250px;
          }

        @media screen and (max-width: 960px) {
          width: 360px
        }

        h2 {
          font-size: 72px;
          font-family: "LilitaOne", sans-serif;
          line-height: 70px;
          text-transform: uppercase;
          // letter-spacing: -2%;
          text-align: left;

          @media screen and (max-width: 1280px) {
          font-size: 32px;
          line-height: 30px;
          }

          @media screen and (max-width: 960px) {
            width: 100%;
            font-size: 32px;
            line-height: 36px;
            text-align: center;
            // letter-spacing: 0;
          }
        }
      }

      &-list {
        width: 749px;

        @media screen and (max-width: 768px) {
          width: 441px;
        }

        @media screen and (max-width: 480px) {
          width: 385px;
        }

        &-item {
          border-bottom: 1px solid #070707;
          padding: 30px 0;

          .question {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            span {
              font-size: 24px;
              font-weight: bold;
              text-align: left;

              @media screen and (max-width: 960px) {
                font-size: 20px;
              }
            }

            .toggle-button {
              width: 44px;
              height: 44px;
              border-radius: 100px;
              color: #fff;
              background: #070707;
              border: none;
              font-size: 34px;
              cursor: pointer;
            }
          }

          .answer {
            text-align: left;
            height: 0;
            overflow: hidden;
            transition: height 0.3s ease;
            font-size: 24px;
            line-height: 1.4;
            padding-right: 45px;

            &--open {
              height: auto;
              margin-top: 10px;
              padding: 10px 0;
            }
          }
        }
      }
    }
  }
}
