.congrat-section {
  height: 876px;
  margin-top: 80px;

  @media screen and (max-width: 480px) {
    height: 686px;
  }

  &_container {
    height: 603px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .stars {
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;

      .star {
        position: absolute;
        animation: float 4s ease-in-out infinite;
      }

      .star1 {
        top: 15%;
        left: 20%;

        @media screen and (max-width: 480px) {
          top: 7%;
        }
      }

      .star2 {
        top: 32%;
        left: 15%;
        animation-delay: 0.5s;
        
        @media screen and (max-width: 480px) {
          top: 14%;
          left: 10%;
        }
      }

      .star3 {
        top: 40%;
        right: 15%;
        animation-delay: 1s;

        @media screen and (max-width: 480px) {
          width: 10%;
          top: 64%;
          right: 83%;
        }

  
      }

      .star4 {
        top: 33%;
        right: 10%;
        animation-delay: 1.5s;

        
        @media screen and (max-width: 480px) {
          top: 46%;
        }
      }

      .star5 {
        top: 60%;
        right: 10%;
        animation-delay: 2s;

        @media screen and (max-width: 480px) {
          top: 66%;
        }
      }
    }

    &-block {
      width: 640px;
      height: 363px;
      text-align: center;

      @media screen and (max-width: 480px) {
        width: 360px;
      }

      &_title {
        h2 {
          text-transform: uppercase;
          font-size: 40px;
          font-family: "LilitaOne", sans-serif;

          @media screen and (max-width: 480px) {
            font-size: 36px;
          }
        }
      }

      &_subtitle {
        font-size: 16px;
        margin-top: 24px;

        &-p {
          font-size: 20px;
          margin-top: 24px;
          font-weight: 700;
        }
      }

      &_h3 {
        margin-top: 40px;
        font-size: 20px;
        font-family: "LilitaOne", sans-serif;
        text-transform: uppercase;
      }

      form {
        display: flex;
        justify-content: center;
        margin-top: 0px;
        gap: 8px;
        flex-direction: row;
        align-items: flex-end;
      }

      &_copy-field {
        width: 285px;
        height: 54px;
        border: 2px solid #ebeaea;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &_container {
          p {
            font-size: 14px;
          }
        }
      }

      &_button {
        display: flex;
        justify-content: center;
        margin-top: 30px;

      }

      &_alert {
        margin-top: 24px;
      }
    }
  }
}
