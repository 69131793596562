.custom-purple-hover-btn {
  padding: 5px 20px;
  border-radius: 10px;
  background-color: #000;
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background: #ccc6f2;
    box-shadow: 4px 4px 0px 0px #070707;
    color: #000;
  }
}
