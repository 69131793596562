.tweet-section {
  height: 876px;
  margin-top: 80px;

  @media screen and (max-width:480px) {
    height: 966px;
  }

  &_container {
    height: 603px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &-block {
      width: 640px;
      height: 363px;
      text-align: center;

      @media screen and (max-width: 678px) {
        max-width: 460px;
      }

      @media screen and (max-width: 480px) {
        max-width: 345px;
      }

      &_title {
        h2 {
          text-transform: uppercase;
          font-size: 40px;
          font-family: "LilitaOne", sans-serif;
        }
      }

      &_subtitle {
        font-size: 16px;
        margin-top: 24px;

        &-h3 {
          font-size: 20px;
          margin-top: 24px;
          font-family: "LilitaOne", sans-serif;
        }
      }

      &_tweet-item {
        padding: 25px 25px 5px 25px;
        margin-top: 40px;
        width: 587px;
        min-height: 226px;
        border: 1px solid #606060;
        box-shadow: 4px 4px 0px 0px #606060;
        border-radius: 20px;

        @media screen and (max-width: 678px) {
          max-width: 415px;
        }


        @media screen and (max-width: 480px) {
          max-width: 295px;
        }

        &__top {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          img {
            cursor: pointer;
          }

          span {
            color: #209bdd;
            font-size: 16px;
            font-weight: 700;
          }
        }

        &__content {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 30px;
          margin: 30px 0;

          img {
            width: 30px;
            height: 30px;
          }

          p {
            width: 478px;
            text-align: left;
            font-size: 20px;
          }
        }

        &__reply {
          color: #209bdd;
          font-size: 16px;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
          margin-bottom: 10px;
        }

        &__bottom_bar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 60px;
          border-top: 1px solid #efefef;

          .left-btns {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 25px;


            @media screen and (max-width: 480px) {
              gap: 10px;
            }
          }

          .action-btn {
            display: flex;
            gap: 8px;

            .check-btn {
              background: none;
              border: solid 2px;
              border-color: #209bdd;
              border-radius: 100px;
              color: #209bdd;
              cursor: pointer;
              font-size: 16px;
              font-weight: 700;
              padding: 9px 23px;

              @media screen and (max-width:480px) {
                color: #fff;
                background: none;
                border: solid 2px;
                border-color: #209bdd;
                color: #209bdd;
                border-radius: 100px;
                cursor: pointer;
                font-size: 12px;
                font-weight: 700;
                padding: 8px 13px;
              }

              &:hover {
                border-color: #4EBDF8;
                color: #4EBDF8;
                background: transparent;
              }
            }


            button {
              color: #fff;
              background: #209bdd;
              border-radius: 100px;
              border: none;
              cursor: pointer;
              font-size: 16px;
              font-weight: 700;
              padding: 9px 23px;

              @media screen and (max-width:480px) {
                color: #fff;
                background: #209bdd;
                border-radius: 100px;
                border: none;
                cursor: pointer;
                font-size: 12px;
                font-weight: 700;
                padding: 8px 13px;
              }

              &:hover {
                background-color: #4EBDF8;
              }
            }
          }
        }
      }

      &_button {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        // button {
        //   width: 200px;
        //   height: 41px;
        //   padding: 10px 35px;
        //   border-radius: 10px;
        //   background-color: #000;
        //   color: #fff;
        //   font-size: 14px;
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        // }
      }

      &_alert {
        margin-top: 25px;
      }
    }
  }
}