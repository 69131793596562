.alredy-joined-section {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 456px;
  background: #ccc6f1;

  @media screen and (max-width: 960px) {
    height: 389px;
  }

  &_title {
    margin-bottom: 35px;
    h2 {
      font-size: 40px;
      font-family: "LilitaOne", sans-serif;
      text-align: center;
      text-transform: uppercase;

      @media screen and (max-width: 960px) {
        font-size: 32px;
      }
    }
  }

  &_amount {
    text-align: center;
    display: flex;
    flex-direction: column;
    span:first-child {
      font-size: 100px;
      color: #ffffff;
      font-family: "LilitaOne", sans-serif;
      -webkit-text-stroke: 3px #000000;
    }
    span {
      font-size: 16px;
    }
  }
}
