// src/styles/fonts.scss
@font-face {
  font-family: "Outfit";
  src: url("./Outfit-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "LilitaOne";
  src: url("./LilitaOne-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

// body {
//   font-family: "Outfit", sans-serif;
// }

button {
  font-family: "Outfit", sans-serif;
  border-width: 0;
}
